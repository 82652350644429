@import 'src/mixins.scss';

.recordingPanelHead {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.recordingPanelContent {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: start;
  gap: 1rem;
}

.micIcon {
  margin-top: 1.5rem;
  font-size: 3rem;
}

.inputs {
  min-width: 0;
}

.inputFormItem {
  margin-bottom: 0;
}

.feedback {
  padding: 0.25rem 0.5rem;
}

.micControls {
  margin-top: 0.5rem;
}

.controlsButton {
  margin-top: 1.5rem;
  height: 3rem;
}
