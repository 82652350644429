.eventInfo {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.icon {
  font-size: 2rem;
}

.qrCode {
  border: 3px solid var(--ant-color-primary);
  width: 5rem;
  height: 5rem;
  border-radius: var(--ant-border-radius);

  img {
    width: 100%;
  }
}