@import 'src/mixins.scss';

.form {
  label {
    font-weight: var(--ant-font-weight-strong);
  }

  .checkbox {
    :global(.ant-row) {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      @include breakpoint-sm {
        display: flex;
      }
    }

    label {
      font-weight: var(--ant-font-weight-base);
      text-align: start;
    }
  }
}
