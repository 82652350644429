@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --color-primary: #ffae43;
  --color-primary-hover: #ffbb62;
  --color-primary-light: #ffdaa9;
  --color-accent: #90d7ff;
  --color-text: #444;
  --color-border: #D9D9D9;
  --color-error: #ff7d4b;
  --color-error-hover: #ff9f73;
  --color-modal-background: #4448;

  --font-weight-base: 400;
  --font-weight-strong: 600;
  --font-size-base-sm: 13px;
  --font-size-base: 16px;

  --border-radius: 1rem;
  --border-radius-lg: 16px;
  --border-radius-sm: 4px;
}


body {
  color: var(--color-text);
  padding: 0px;
  margin: 0px;
  background: linear-gradient(180deg, #fdfaf6 0%, #f7f0e4 56%, #ffe9cb 100%);
  background-attachment: fixed;
}

a {
  color: var(--color-primary);
  text-decoration: none;

  :hover {
    color: var(--color-primary-hover);
  }
}

h2 {
  font-size: 1.1rem;
  margin-top: 0;
  font-weight: var(--font-weight-strong);
}

:global {
  .container {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
  }
}

:global {
  .ant-card {
    margin-top: 0.5rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

    .ant-card-head {
      border-bottom-color: var(--color-border);
    }
  }
}
