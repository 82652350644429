.subtitlesFooter {
  padding-left: var(--subs-page-padding);
  padding-right: var(--subs-page-padding);
  height: var(--header-footer-height);
  display: flex;
  justify-content: space-between;
  background-color: var(--subs-theme-color-bg-secondary);
}

.toolbar {
  display: flex;
  align-items: center;
}

.toolbarButton {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--subs-theme-color-bg-secondary);
  border: none;
  color: var(--subs-theme-color-fg-secondary);
  font-size: 1.2rem;
  padding: 0.4rem;

  &:hover {
    background-color: var(--subs-theme-color-bg-accent);
  }
}

.langSelect {
  width: 12rem;

  &:global(.ant-select-outlined > .ant-select-selector) {
    background-color: var(--subs-theme-color-bg-secondary);
    color: var(--subs-theme-color-fg-secondary);
    border: 1px solid var(--subs-theme-color-border);
  }

  &:global(.ant-select-open .ant-select-selection-item) {
    color: var(--subs-theme-color-fg-secondary);
  }

  &:global(.ant-select-outlined > .ant-select-arrow) {
    color: var(--subs-theme-color-fg-secondary);
  }

  &Popup {
    background-color: var(--subs-theme-color-bg-secondary);
    border: 1px solid var(--subs-theme-color-border);
  }

  &Option {
    &:global(.ant-select-item) {
      background-color: var(--subs-theme-color-bg-secondary);
      color: var(--subs-theme-color-fg-secondary);
    }

    &:global(.ant-select-item-option-active:not(.ant-select-item-option-disabled)) {
      background-color: var(--subs-theme-color-bg-accent);
      color: var(--subs-theme-color-fg-secondary);
    }

    &:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
      background-color: var(--subs-theme-color-bg-accent);
      color: var(--subs-theme-color-fg-secondary);
    }
  }
}

.fontSize {
  :global(.ant-dropdown-menu) {
    background-color: var(--subs-theme-color-bg-secondary);
    color: var(--subs-theme-color-fg-secondary);
    border: 1px solid var(--subs-theme-color-border);
  }

  :global(.ant-dropdown-menu .ant-dropdown-menu-item) {
    background-color: var(--subs-theme-color-bg-secondary);
    color: var(--subs-theme-color-fg-secondary);
    border-radius: var(--border-radius-lg);
  }

  :global(.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-active) {
    background-color: var(--subs-theme-color-bg-accent);
    color: var(--subs-theme-color-fg-secondary);
  }

  :global(.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected) {
    background-color: var(--subs-theme-color-bg-accent);
    color: var(--subs-theme-color-fg-secondary);
    font-weight: bold;
  }
}
