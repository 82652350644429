.toolbar {
  margin-bottom: 1rem;
}

.eventItem {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-border);
}
