.form {
  label {
    font-weight: var(--ant-font-weight-strong);
  }
}

.slug {
  font-weight: var(--ant-font-weight-strong);
}

.url {
  &Base {
    padding-left: var(--ant-form-vertical-label-padding);
  }

  &Base,
  .slug {
    font-family: monospace;
    font-size: var(--ant-font-size-lg);
  }
}

.editButton {
  &:hover {
    color: var(--ant-color-primary-hover);
  }
}
