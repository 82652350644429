@import 'src/mixins.scss';

.cardHead {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.cardContent {
  max-width: 24rem;
}
