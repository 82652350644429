.alertPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--subs-page-padding);
  background-color: var(--subs-theme-color-bg-primary);
  color: var(--subs-theme-color-fg-primary);
  height: 100%;
}

.alert {
  display: flex;
  align-items: flex-start;
  max-width: 32rem;
  background-color: var(--subs-theme-color-bg-secondary);
  color: var(--subs-theme-color-fg-secondary);
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 1rem;

  h2 {
    margin: 0;
  }

  p {
    margin: 0.5rem 0 0 0;
  }
}

.smallAlert {
  gap: 1rem;
  align-items: center;
}

.smallIcon {
  font-size: 1.5rem;
}

.largeIcon {
  font-size: 3rem;
}
