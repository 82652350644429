@import 'src/mixins.scss';

.subtitlesPage {
  --header-footer-height: 3rem;
  --subs-page-padding: 0.5rem;
  --subs-font-size: 7vw;
  
  --subs-theme-color-bg-primary: #fff;
  --subs-theme-color-fg-primary: #000;
  --subs-theme-color-border: #888;
  --subs-theme-color-bg-secondary: #f7f0e4;
  --subs-theme-color-bg-accent: #ffe9cb;
  --subs-theme-color-fg-secondary: #333;

  &[data-theme='dark'] {
    --subs-theme-color-bg-primary: #000;
    --subs-theme-color-fg-primary: #fff;
    --subs-theme-color-border: #888;
    --subs-theme-color-bg-secondary: #333;
    --subs-theme-color-bg-accent: #666;
    --subs-theme-color-fg-secondary: #fff;
  }

  display: grid;
  grid-template-rows: var(--header-footer-height) calc(100dvh - 3rem - 3rem) var(--header-footer-height);
  height: 100dvh;
  background-color: var(--subs-theme-color-bg-primary);
  overflow-y: hidden;

  @include breakpoint-sm {
    --subs-page-padding: 1rem;
    --subs-font-size: 6vw;
  }

  @include breakpoint-sm {
    --subs-page-padding: 1.5rem;
    --subs-font-size: 5vw;
  }

  @include breakpoint-md {
    --subs-page-padding: 2rem;
    --subs-font-size: 5vw;
  }

  @include breakpoint-lg {
    --subs-page-padding: 2.5rem;
    --subs-font-size: 4vw;
  }

  @include breakpoint-xl {
    --subs-page-padding: 3rem;
    --subs-font-size: 3vw;
  }
}

.noUserSelect {
  user-select: none;
}

.noControls {
  cursor: none;
  grid-template-rows: 0 100dvh 0;
  transition: grid-template-rows 1s;
}

@keyframes controls {
  0% {
    grid-template-rows: var(--header-footer-height) calc(100dvh - 3rem - 3rem) var(--header-footer-height);
  }
  100% {
    grid-template-rows: 0 100dvh 0;
  }
}

.alertContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
