.segmentItem {
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
    font-size: 1rem;
  }

  &:last-child {
    border-bottom: none;
  }
}

.icon {
  font-size: 1.2rem;
}

.transcriptions {
  display: flex;
  gap: 0.5rem;
}

.deleteIcon:hover {
  color: var(--ant-color-error);
}
