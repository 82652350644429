.eventPage {
  max-width: 840px;
  margin: 0 auto;
}

.mainCard {
  margin-top: 1rem;
}

.menu {
  padding: 0.5rem;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 1rem;
  margin: 1rem 0;
}
